import React, { useEffect } from 'react';
import './ChakraPage.css';
import { FaYoutube, FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { chakraPageData } from '../../DATA';

const ChakraPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="c-container">
            {/* Navbar */}
            <nav className="c-nav">
                <div className="c-logo">{chakraPageData.navbar.logo}</div>
                <div className="c-nav-links">
                    {chakraPageData.navbar.links.map((link, index) => (
                        <a key={index} href={link.url} className="c-nav-link">
                            {link.text}
                        </a>
                    ))}
                </div>
            </nav>

            {/* Hero Section */}
            <div className="c-hero">
                <h1>{chakraPageData.heroSection.title}</h1>
                <p>{chakraPageData.heroSection.description}</p>
                <button onClick={() => window.location.href = chakraPageData.heroSection.ctaLink}>
                    {chakraPageData.heroSection.ctaText}
                </button>
            </div>

            {/* About Section */}
            <section id="about" className="c-about-section">
                <div className="c-about-container">
                    <div className="c-about-header">
                        <h2>{chakraPageData.aboutSection.header.title}</h2>
                        {chakraPageData.aboutSection.header.description.map((paragraph, index) => (
                            <p key={index}>{paragraph}</p>
                        ))}
                    </div>

                    <div className="c-mission-vision">
                        <div className="c-mission">
                            <h3>{chakraPageData.aboutSection.mission.title}</h3>
                            <p>{chakraPageData.aboutSection.mission.description}</p>
                        </div>
                        <div className="c-vision">
                            <h3>{chakraPageData.aboutSection.vision.title}</h3>
                            <p>{chakraPageData.aboutSection.vision.description}</p>
                        </div>
                    </div>

                    <div className="c-core-values">
                        <h3>{chakraPageData.aboutSection.coreValues.title}</h3>
                        <div className="c-values-quadrant">
                            {chakraPageData.aboutSection.coreValues.items.map((value, index) => (
                                <div key={index} className="c-value-item">
                                    <h4>{value.title}</h4>
                                    <p>{value.description}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>

            {/* Projects Section */}
            <section id="projects" className="c-projects-section">
                <div className="c-projects-container">
                    <div className="c-projects-header">
                        <h2>{chakraPageData.projectsSection.header.title}</h2>
                    </div>

                    <div className="c-project-content">
                        <div className="c-project-intro">
                            <h3>{chakraPageData.projectsSection.project.title}</h3>
                            <p className="c-project-tagline">{chakraPageData.projectsSection.project.tagline}</p>
                        </div>

                        <div className="c-project-overview">
                            <p>{chakraPageData.projectsSection.project.overview}</p>
                        </div>

                        {/* Feature Grid */}
                        <div className="c-features-grid">
                            {chakraPageData.projectsSection.project.features.map((feature, index) => (
                                <div key={index} className="c-feature-column">
                                    <h4>{feature.title}</h4>
                                    <ul>
                                        {feature.items.map((item, itemIndex) => (
                                            <li key={itemIndex}>{item}</li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>

                        {/* Technical Capabilities */}
                        <div className="c-capabilities-section">
                            <h4>Technical Capabilities</h4>
                            <div className="c-capabilities-grid">
                                {chakraPageData.projectsSection.project.technicalCapabilities.map((capability, index) => (
                                    <div key={index} className="c-capability-card">
                                        <h5>{capability.title}</h5>
                                        <ul>
                                            {capability.items.map((item, itemIndex) => (
                                                <li key={itemIndex}>{item}</li>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/* Environmental Impact */}
                        <div className="c-impact-workflow">
                            <div className="c-impact-section">
                                <h4>Environmental Impact</h4>
                                <div className="c-impact-grid">
                                    {chakraPageData.projectsSection.project.environmentalImpact.map((impact, index) => (
                                        <div key={index} className="c-impact-card">
                                            <h5>{impact.title}</h5>
                                            <ul>
                                                {impact.items.map((item, itemIndex) => (
                                                    <li key={itemIndex}>{item}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {/* Workflow */}
                            <div className="c-workflow-section">
                                <h4>How It Works</h4>
                                <div className="c-workflow-steps">
                                    {chakraPageData.projectsSection.project.workflow.map((step, index) => (
                                        <div key={index} className="c-step">
                                            <h5>{step.step}</h5>
                                            <p>{step.description}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <footer className="c-footer">
                <div className="c-footer-content">
                    <p>CHAKRA @ 2024. All rights reserved.</p>
                    <div className="c-social-icons">
                        <a href="#" aria-label="YouTube"><FaYoutube size={24} /></a>
                        <a href="#" aria-label="Facebook"><FaFacebook size={24} /></a>
                        <a href="https://x.com/Yuktii_Ventures" aria-label="Twitter"><FaXTwitter size={24} /></a>
                        <a href="#" aria-label="Instagram"><FaInstagram size={24} /></a>
                        <a href="https://www.linkedin.com/company/yuktii-ventures/" aria-label="LinkedIn"><FaLinkedin size={24} /></a>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default ChakraPage;