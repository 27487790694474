import React, { useEffect } from 'react';
import './AnnamPage.css'
import corevaluesImg from '../../assets/annampage-images/core-values.png'
import { FaYoutube, FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';

const AnnamPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const navigate = useNavigate();

    return (
        <>
            <div className="container">
                {/* Navbar */}
                <nav className="nav">
                    <div className="logo">ANNAM</div>
                    <div className="nav-links">
                        <a href="#about" className="nav-link">About</a>
                        {/* <a href="#products" className="nav-link">Products</a> */}
                        <a href="#projects" className="nav-link">Projects</a>
                    </div>
                </nav>

                {/* Landing Page Section */}
                <section className="landing-section">
                    <div className="landing-content">
                        <h1 className="landing-title">Revolutionize Your Food Choices with AI-Powered Insights</h1>
                        <p className="landing-text">
                            Empowering you to make informed, sustainable, and nutritious food decisions that benefit your health, your community, and the planet.
                        </p>
                        <button className="button" onClick={() => window.location.href = '#about'}>Learn More About ANNAM</button>
                    </div>
                </section>

                {/* About Section */}
                <section id='about' className="about-section">
                    <h2 className="about-title">About ANNAM</h2>
                    <div className="about-grid">
                        <div className="about-card">
                            <h3 className="card-title">Our Mission</h3>
                            <p className="card-text">
                                At ANNAM, we are on a mission to revolutionize global food systems through the power of artificial intelligence. We strive to create a world where sustainable, nutritious, and affordable food is accessible to all, while minimizing environmental impact and maximizing resource efficiency.
                            </p>
                        </div>
                        <div className="about-card">
                            <h3 className="card-title">Our Vision</h3>
                            <p className="card-text">
                                We envision a future where AI-driven solutions seamlessly integrate with every aspect of the food chain, from farm to fork. By harnessing the potential of machine learning, data analytics, and automation, we aim to build resilient, transparent, and equitable food systems that nourish both people and planet.
                            </p>
                        </div>
                        <div className="about-card">
                            <h3 className="card-title">Our Approach</h3>
                            <p className="card-text">
                                ANNAM combines cutting-edge AI technology with deep expertise in food systems to create innovative solutions. We work closely with farmers, food producers, distributors, and consumers to develop tools and strategies that optimize resource use, reduce waste, and promote sustainable practices throughout the entire food value chain.
                            </p>
                        </div>
                    </div>
                </section>

                <section className='core-values'>
                    <h1 className="core-values-title">Our Core Values</h1>
                    <img src={corevaluesImg} alt="core-values" />
                </section>

                <div id='projects' className="projects-section">
                    <h1 className="projects-title">ANNAM'S Projects</h1>
                    <div
                        className="bhojanam-card"
                        onClick={() => navigate('/annam/bhojanam')}
                    >
                        <h2 className="bhojanam-title">BHOJANAM</h2>
                        <p className="bhojanam-description">
                            The Sustainable Breakfast Cereals and Beverages Initiative is a
                            groundbreaking project under the ANNAM (AI-Driven Sustainable
                            Food Systems) platform. This initiative aims to revolutionize the
                            breakfast food industry by promoting sustainability, reducing
                            waste, and engaging communities through innovative AI-driven
                            solutions and digital storytelling.
                        </p>
                    </div>
                </div>

                <footer className="p-footer">
                    <div className="p-footer-content">
                        <p>ANNAM @ 2024. All rights reserved.</p>
                        <div className="p-social-icons">
                            <a href="#" aria-label="YouTube"><FaYoutube size={24} /></a>
                            <a href="#" aria-label="Facebook"><FaFacebook size={24} /></a>
                            <a href="https://x.com/Yuktii_Ventures" aria-label="Twitter"><FaXTwitter size={24} /></a>
                            <a href="#" aria-label="Instagram"><FaInstagram size={24} /></a>
                            <a href="https://www.linkedin.com/company/yuktii-ventures/" aria-label="LinkedIn"><FaLinkedin size={24} /></a>
                        </div>
                    </div>
                </footer>
            </div>
        </>
    );
};

export default AnnamPage;