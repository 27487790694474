/* LANDING PAGE DATA */
import HeroImg from "./assets/images/10.jpeg";
import whatwedoimg1 from "./assets/images/1.jpg";
import whatwedoimg2 from "./assets/images/2.jpg";
import whatwedoimg3 from "./assets/images/3.jpg";
import whatwedoimg4 from "./assets/images/4.jpg";
import reviewImg from "./assets/images/9.jpeg";
import venture1 from "./assets/images/6.jpg";
import venture2 from "./assets/images/12.jpeg";
import venture3 from "./assets/images/11.jpeg";
import profileImg from "./assets/images/profileImg.png";
import blog1 from "./assets/blogImages/blog1.png";
import blog2 from "./assets/blogImages/blog2.png";
import blog3 from "./assets/blogImages/blog3.png";
import blog4 from "./assets/blogImages/blog4.png";
import replaceImg from "./assets/images/replaceImg.png";
import podcastHeroImg from "./assets/images/podcast-heroImg3.png";
import desktopImage from "./assets/parinaampage-images/Desktop.png";
import dashboardImage from "./assets/parinaampage-images/Dashboard.png";
import phoneImage from "./assets/parinaampage-images/Phone.png";
import videoImg from "./assets/parinaampage-images/videoImg.png";



export const blogData = [
    {
        title: "Is Your Career Ready for the Future? PARINAAM Can Help...",
        content: "In today's competitive job market, standing out can feel like finding a needle in a haystack. Everyone seems to have access to the same tools and resources, making it difficult to differentiate yourself based solely on qualifications and experience. That's where PARINAAM comes in. ",
        source: "Yuktii Ventures",
        date: "September 6, 2024",
        image: blog4,
        link: "https://www.linkedin.com/posts/yuktii-ventures_futureofwork-professionalcoach-careerdevelopment-activity-7237541343177117696-FALP?utm_source=share&utm_medium=member_desktop"
    },
    {
        title: "DEEPTECH ATELIER RIGA",
        content: "DEEPTECH ATELIER RIGA - Excellent event once again! Big applause for the organizers - Latvijas Investīciju un attīstības aģentūra - LIAA and Commercialization Reactor for having us.",
        source: "Radical Innovations Group",
        date: "June, 2024",
        image: blog3,
        link: "https://www.linkedin.com/posts/rigv_deeptech-atelier-riga-excellent-event-once-activity-7201690117780971521-zb8b?utm_source=share&utm_medium=member_desktop"
    },
    {
        title: "Swiss Climate Scores: A New Paradigm or Problem for Investors?",
        content:
            "The world is facing a climate crisis, and businesses and investors have a vital role to play in addressing it. In Switzerland, the government is taking bold steps to support the transition to a net-zero economy",
        source: "Dr. Krishnaswamy SANKARAN",
        date: "October 27, 2023",
        image: blog2,
        link: "https://www.linkedin.com/pulse/swiss-climate-scores-new-paradigm-problem-investors-sankaran-zn9kc/?trackingId=lGA1XsHzRbi46lVW7p92Sw%3D%3D"
    },
    {
        title: "Dr. Krishnaswamy Sankaran wins Nordic Energy Challenge 2021",
        content:
            "With descriptive illustrations, a well-reasoned selection of sources, and an energetic presentation, Krishnaswamy Sankaran demonstrates remarkable understanding of the barriers and opportunities faced by the Nordic transport sector. ",
        source: "Nordic Energy Research",
        date: "Oct 14, 2021",
        image: blog1,
        link: "https://www.nordicenergy.org/article/dr-krishnaswamy-sankaran-wins-nordic-energy-challenge/"
    },

    // ... Add more writing entries as needed
];

export const landingPageData = {
    hero: {
        title: "We build, scale, and own wonderful deep tech ventures.",
        description: "YUKTII is committed to leveraging deep tech to drive innovation and progress. We strive to create solutions that empower people and foster growth."
    },
    featureSection: {
        title: "Empowering the future through technology.",
        description: "At YUKTII, we see technology as a tool to empower people, businesses, and communities. Our mission is to push boundaries and create opportunities for growth.",
        imagePath: HeroImg,
    },
    companyStats: [
        { number: "120,000+", label: "Active Users" },
        { number: "750+", label: "★★★★★" },
        { number: "10", label: "Awards" }
    ],
    blogPosts: blogData.slice(0, 3),
};

/* VENTURES PAGE DATA */

export const venturesPageData = {
    title: "We build, scale, and own amazing deep tech ventures.",
    description: "Our mission is to create a lasting impact through deep tech. We are passionate about developing solutions that address real-world challenges, from empowering careers to revolutionizing e-waste management and food systems.",
    companies: [
        {
            name: "PARINAAM",
            imagePath: venture1,
            url: "/parinaam"
        },
        {
            name: "CHAKRA",
            imagePath: venture2,
            url: "/chakra"
        },
        {
            name: "ANNAM",
            imagePath: venture3,
            url: "/annam"
        }
    ],
    vision: {
        title: "OUR VISION",
        description: "Our vision is to be a global leader in innovation, setting new standards for excellence in AI-powered solutions and digital transformation. We envision a future where YUKTII's ventures serve as catalysts for positive change, empowering people and businesses to adapt, thrive, and lead in an ever-evolving world."
    },
    exploreSection: {
        title: "Ready to explore how YUKTII Ventures are creating impact?",
        l0: "🚶‍♂️ Stay tuned for our updates. Follow us on the social media below. ",
        l1: "PARINAAM for Transformative Career Development",
        l2: "DRISHTI for e-Waste Resource Recovery",
        l3: "ANNAM for Food Companion",
        buttonText: "Get in touch"
    }
};

/* ABOUT PAGE DATA */

export const aboutPageData = {
    hero: {
        title: "YUKTII Ventures: Shaping the Future with Deep Tech",
        description: [
            "Small ventures with a big vision.",
            "At YUKTII Ventures, we're not just building businesses; we're creating a legacy. Our mission is to leverage deep tech to drive positive change, and we're doing this by developing innovative solutions."
        ]
    },
    whatWeDo: {
        title: "What we do",
        description: "At YUKTII, we drive innovation across multiple industries by leveraging cutting-edge technologies like AI, ML, Edge-AI, IoT and data analytics. Our ventures span diverse sectors, including career growth platforms, SaaS applications, and business solutions. We empower individuals and organizations to thrive in the digital age by providing tools that enhance skills, transform careers, improve e-waste recycling and evaluate healthy food choices.",
        imagePath1: whatwedoimg1,
        imagePath2: whatwedoimg2,
        imagePath3: whatwedoimg3,
        imagePath4: whatwedoimg4
    },
    missionVision: {
        mission: {
            title: "OUR MISSION",
            description: "Our mission is to create a lasting impact through deep tech. We are passionate about developing solutions that address real-world challenges, from empowering careers to revolutionizing e-waste management, and transforming food consumption behaviors."
        },
        vision: {
            title: "OUR VISION",
            description: "Our vision is to be a global leader in innovation, setting new standards for excellence in AI-powered solutions and digital transformation. We envision a future where YUKTII's ventures serve as catalysts for positive change, empowering people and businesses to adapt, thrive, and lead in an ever-evolving world."
        }
    },
    coreValues: {
        title: "Our Approach",
        values: [
            {
                title: "Deep Tech at the Core",
                description: "Everything we do is grounded in cutting-edge technology. Our team of experts is dedicated to harnessing the power of AI, machine learning, and other advanced deep technologies to create solutions that truly make a difference."
            },
            {
                title: "Real-World Impact",
                description: "Our ventures, PARINAAM, DRISHTI, and ANNAM, are designed to address pressing challenges and provide tangible benefits to users. From empowering individuals to build fulfilling careers to revolutionizing waste management and promoting sustainable food practices, our solutions are making a positive impact on the world."
            },
            {
                title: "A Proven Track Record",
                description: "Our team brings years of experience in industry, R&D, and private equity. These expertise allow us to navigate complex challenges, identify market opportunities, and deliver exceptional results."
            },
            {
                title: "A Strong Network",
                description: "We have cultivated a powerful network of partners from industries, academia, research labs, governments, and, international organizations. This network provides us with the resources, support, and insights needed to scale our ventures and achieve our goals."
            },
            // Add more core values here
        ]
    },
    team: [
        {
            name: "Name",
            position: "Position",
            imagePath: profileImg
        },
        {
            name: "Name",
            position: "Position",
            imagePath: profileImg
        },
        {
            name: "Name",
            position: "Position",
            imagePath: profileImg
        },
        {
            name: "Name",
            position: "Position",
            imagePath: profileImg
        },
        // Add more team members here
    ],
    workWithUs: {
        title: "Follow Our Journey",
        description: "If you're passionate about deeptech and share our vision of creating a better future, we invite you to follow our social media pages below. Follow us in our journey to solve real-world problems and create lasting value.",
        buttonText: "View job openings"
    }
};


/* Writings Page Data */


export const writingsPageData = {
    pageTitle: "We have ideas about how businesses should be run.",
    pageDescription: "Below is a collection of podcasts, articles, opinions and thoughts we have on business and tech. For more content like this, follow Yuktii on Medium and Twitter.",
    buttonCategories: ["Interviews", "Writings"],
    writings: blogData,
    contributeSection: {
        description: "Have valuable insights, ideas, or experiences you'd like to share with our community? We're always looking for fresh perspectives from passionate individuals. Whether it's a blog post, article, or creative content, your contribution can make a difference. Join us in shaping the conversation and inspiring others by sharing your knowledge.",
        buttonText: "Contribute now"
    }
};



export const podcastPageData = {
    hero: {
        title: "EXPLORING AI INNOVATIONS WITH YUKTII VENTURES",
        description: "DIVE INTO THE WORLD OF AI-DRIVEN SOLUTIONS TRANSFORMING CAREERS, RECYCLING, AND NUTRITION",
        imagePath: podcastHeroImg
    },
    empoweringVoice: {
        title: "EMPOWERING YOUR VOICE",
        description: "Welcome to 'AI Horizons,' the podcast that brings you cutting-edge discussions on artificial intelligence applications.",
        imagePath: replaceImg
    },
    categories: [
        { title: 'COMEDY', imagePath: replaceImg },
        { title: 'HORROR', imagePath: replaceImg },
        { title: 'STORY TELLING', imagePath: replaceImg },
    ],
    episodes: [
        { title: 'LAUGHOLOGY LAB', host: 'With Kevin Gorczany', imagePath: replaceImg },
        { title: 'LAUGHOLOGY LAB', host: 'With Kevin Gorczany', imagePath: replaceImg },
        { title: 'LAUGHOLOGY LAB', host: 'With Kevin Gorczany', imagePath: replaceImg },
        { title: 'TECH TRENDS', host: 'With Sarah Johnson', imagePath: replaceImg },
        { title: 'MYSTERY HOUR', host: 'With John Smith', imagePath: replaceImg },
    ],
    guests: [
        { name: 'NESSIE ARDEN', podcast: 'Beyond the Canvas', imagePath: replaceImg },
        { name: 'LENA MARSH', podcast: 'Whispers in the Dark', imagePath: replaceImg },
        { name: 'JARED ROE', podcast: 'Frequency Flux', imagePath: replaceImg },
        { name: 'EMMA STONE', podcast: 'Tech Talk', imagePath: replaceImg },
        { name: 'JOHN DOE', podcast: 'Mystery Hour', imagePath: replaceImg },
    ],
    contact: {
        imagePath: replaceImg
    }
};


/* NAVBAR COMPONENT DATA */

export const navbarData = {
    logo: "YUKTII VENTURES",
    links: [
        { text: "Ventures", url: "/ventures" },
        { text: "About", url: "/about" },
        { text: "Podcast", url: "/podcast" },
    ],
    dropdownLinks: [
        { text: "Writing", url: "/writing" },
        // { text: "Newsletter", url: "#" },
    ],
    contactLink: { text: "Get in touch", url: "#" }
};


/* FOOTER SECTION DATA */

export const footerData = {
    logo: "YUKTII",
    columns: [
        {
            links: [
                { text: "Ventures", url: "/ventures" },
            ]
        },
        {
            links: [
                { text: "About Us", url: "/about" },
            ]
        },
        {
            links: [
                { text: "Writing", url: "/writing" },
            ]
        },
        {
            links: [
                { text: "Podcast", url: "/podcast" },
            ]
        },
        // {
        //     links: [
        //         { text: "Contact Us", url: "#" },
        //         { text: "Newsletter", url: "#" },
        //     ]
        // },
    ],
    newsletterSignup: {
        text: "Sign up for Yuktii, a monthly digest of what we're thinking, learning, and looking to do next.",
        placeholder: "Email address"
    },
    backToTop: "BACK TO TOP",
    bottomLinks: [
        { text: "Terms of Service", url: "/termsofservice" },
        { text: "Privacy Policy", url: "/privacypolicy" },
        // { text: "Cookie Policy", url: "/" },
        // { text: "Security", url: "/" },
    ]
};

/*  PARINAAM LANDING PAGE DATA */

const parinaamLandingPageData = {
    heroSection: {
        title: "PARINAAM: Your AI Career Companion",
        description: "Unlock your professional potential with our AI-driven career growth toolkit",
        ctaText: "Get Started",
        ctaLink: "https://parinaam.yuktii.com",
        images: [
            { src: desktopImage, alt: "Desktop", className: "p-desktop-image" },
            { src: dashboardImage, alt: "Dashboard", className: "p-dashboard-image" },
            { src: phoneImage, alt: "Phone", className: "p-phone-image" }
        ]
    },
    featuresSection: {
        title: "Empowering Your Career Growth",
        features: [
            "AI-Powered Resume Builder",
            "Personalized Career Path Recommendations",
            "Skills Gap Analysis",
            "Interview Preparation Assistant"
        ]
    },
    videoSection: {
        title: "Revolutionizing Career Development",
        description: "PARINAAM leverages cutting-edge AI to analyze your skills, experience, and career goals. Our intelligent algorithms provide tailored advice to help you make informed decisions about your professional future.",
        ctaText: "TRY PARINAAM NOW",
        ctaLink: "https://parinaam.yuktii.com",
        videoImage: videoImg
    },
    featuresDetails: {
        title: "Accelerate Your Career Growth",
        features: [
            {
                title: "Craft Perfect Resumes",
                description: "Our AI analyzes job descriptions to help you create tailored, ATS-friendly resumes."
            },
            {
                title: "Discover Opportunities",
                description: "Get personalized job recommendations based on your unique skill set and career aspirations."
            },
            {
                title: "Ace Your Interviews",
                description: "Practice with our AI-powered interview simulator and receive instant feedback."
            },
            {
                title: "Upskill Strategically",
                description: "Identify and bridge skill gaps with custom learning plans."
            }
        ]
    },
    reviews: [
        {
            text: "PARINAAM transformed my job search. The AI-powered resume builder helped me land interviews at top companies, and the career path recommendations opened doors I never knew existed.",
            name: "Priya",
            position: "Software Engineer"
        },
        {
            text: "PARINAAM transformed my job search. The AI-powered resume builder helped me land interviews at top companies, and the career path recommendations opened doors I never knew existed.",
            name: "Ramesh",
            position: "Software Engineer"
        },
        {
            text: "PARINAAM transformed my job search. The AI-powered resume builder helped me land interviews at top companies, and the career path recommendations opened doors I never knew existed.",
            name: "Rakesh",
            position: "Software Engineer"
        },
    ],
    faqs: [
        {
            question: "Who should use the platform?",
            answer: "Our app is designed for job seekers, career changers, and professionals looking to transform their careers."
        },
        {
            question: "What is included with my subscription?",
            answer: "Your subscription includes access to AI-powered PARINAAM tool which has our comprehensive set of tools for career transformation including interview preparations and skills gap analyses."
        },
        {
            question: "Is my personal information safe?", answer: (
                <>
                    Yes, we take data security seriously. For more information, visit our{" "}
                    <a href="/privacypolicy" style={{ color: 'var(--light-color)' }}>
                        Privacy Policy
                    </a>.
                </>
            )
        },
        {
            question: "How can we get in touch?",
            answer: "You can reach our support team via email at RIG-Intern@proton.me or through the contact form on our website."
        },
    ]
};

export { parinaamLandingPageData };



/* CHAKRA PAGE DATA */

const chakraPageData = {
    navbar: {
        logo: "CHAKRA",
        links: [
            { text: "About", url: "#about" },
            { text: "Projects", url: "#projects" },
        ],
    },
    heroSection: {
        title: "Transforming (electronic) e-Waste Recycling Through AI Innovation",
        description: "Pioneering sustainable solutions at the intersection of artificial intelligence and e-waste recycling, making electronic waste recovery smarter and more efficient.",
        ctaText: "Learn More About CHAKRA",
        ctaLink: "#about",
    },
    aboutSection: {
        header: {
            title: "Reimagining the Future of E-Waste Management",
            description: [
                "Chakra is a pioneering venture that bridges the gap between advanced technology and sustainable e-waste management. In a world where electronic waste has become one of our greatest environmental challenges, we leverage cutting-edge artificial intelligence to revolutionize how electronic components are identified, sorted, and recycled.",
                "Our innovative approach combines computer vision, machine learning, and deep industry expertise to transform the e-waste recycling landscape. Through our flagship product Drishti, we're making precise component identification and material recovery not just possible, but efficient and scalable.",
            ],
        },
        mission: {
            title: "Our Mission",
            description: "To accelerate the world's transition to sustainable electronics recycling through innovative AI solutions.",
        },
        vision: {
            title: "Our Vision",
            description: "Building a future where every electronic component finds its way back into the circular economy.",
        },
        coreValues: {
            title: "Our Core Values",
            items: [
                {
                    title: "Environmental Impact",
                    description: "Committed to reducing e-waste's environmental footprint",
                },
                {
                    title: "Innovation",
                    description: "Pushing technological boundaries to solve complex recycling challenges",
                },
                {
                    title: "Sustainability",
                    description: "Creating lasting solutions for future generations",
                },
                {
                    title: "Accessibility",
                    description: "Making advanced recycling technology available to all",
                },
            ],
        },
    },
    projectsSection: {
        header: {
            title: "Innovations in Action",
        },
        project: {
            title: "Drishti: AI-Powered PCB Recycling and Resource Recovery Toolkit",
            tagline: "Advanced computer vision technology for intelligent PCB component identification and material recovery.",
            overview: "Drishti is our flagship AI-powered solution that revolutionizes PCB analysis in e-waste recycling. By leveraging advanced computer vision and machine learning algorithms, Drishti can instantly identify, classify, and analyze components on end of life-cycle printed circuit boards, making the recycling process more efficient and effective.",
            features: [
                {
                    title: "Intelligent Component Detection",
                    items: [
                        "Real-time identification of PCB components",
                        "Comprehensive component classification",
                        "High-accuracy detection rates",
                        "Support for multiple PCB types and layouts",
                    ],
                },
                {
                    title: "Material Analysis",
                    items: [
                        "Detailed breakdown of component materials",
                        "Identification of precious metals",
                        "Hazardous material detection",
                        "Material value assessment",
                    ],
                },
            ],
            technicalCapabilities: [
                {
                    title: "Advanced Computer Vision Algorithms",
                    items: [
                        "Multi-layer component detection",
                        "High-resolution image processing",
                        "Real-time analysis capabilities",
                    ],
                },
                {
                    title: "Machine Learning Integration",
                    items: [
                        "Continuous learning from new PCB types",
                        "Pattern recognition for component identification",
                        "Adaptive analysis algorithms",
                    ],
                },
                {
                    title: "Comprehensive Reporting",
                    items: [
                        "Detailed component inventory",
                        "Material composition breakdown",
                        "Recycling procedure recommendations",
                        "Value recovery estimates",
                    ],
                },
            ],
            environmentalImpact: [
                {
                    title: "Resource Recovery",
                    items: [
                        "Precise identification of valuable materials",
                        "Optimization of recovery processes",
                        "Reduction in material waste",
                        "Enhanced precious metal recovery",
                    ],
                },
                {
                    title: "Process Efficiency",
                    items: [
                        "Reduced manual inspection time",
                        "Automated component classification",
                        "Streamlined recycling workflows",
                        "Improved accuracy in sorting",
                    ],
                },
                {
                    title: "Sustainability Metrics",
                    items: [
                        "Increased recycling effectiveness",
                        "Reduced environmental contamination",
                        "Enhanced material recovery rates",
                        "Lower processing energy consumption",
                    ],
                },
            ],
            workflow: [
                {
                    step: "Step 1: Image Upload",
                    description: "User uploads a high-resolution PCB image to the system",
                },
                {
                    step: "Step 2: Analysis",
                    description: "Advanced AI algorithms process and analyze the PCB image",
                },
                {
                    step: "Step 3: Component Detection",
                    description: "System identifies and catalogs all components present on the PCB",
                },
                {
                    step: "Step 4: Metadata Generation",
                    description: "Comprehensive report including component types, counts, and specifications",
                },
            ],
        },
    },
};

export { chakraPageData };


/* LANDING PAGE REVIEW SECTION COMPONENT DATA */

export const reviewSectionData = {
    title: "What our users are saying",
    imagePath: reviewImg,
    reviews: [
        {
            text: "YUKTII Ventures has been a game-changer for our business. Their innovative solutions helped us streamline our operations, and their customer service is exceptional.",
            author: "Sophia",
            rating: 5
        },
        {
            text: "Great platform with a strong emphasis on security. The team at YUKTII really knows their stuff and delivers on their promises.",
            author: "Michael",
            rating: 4
        },
        {
            text: "I've had a positive experience overall, though there were a few hiccups along the way. The support team was responsive, which made all the difference.",
            author: "Emily",
            rating: 4
        },
        {
            text: "YUKTII Ventures offers reliable services, but I wish their customization options were more extensive. Nevertheless, they provide good value for money.",
            author: "James",
            rating: 4
        },
        {
            text: "The technology and tools provided by YUKTII have been very beneficial for our growth. Fast support and consistent performance are major highlights.",
            author: "Olivia",
            rating: 5
        },
        {
            text: "Their platform is intuitive and easy to use, but I encountered some minor issues with integration. The team was helpful in resolving them, though.",
            author: "Liam",
            rating: 4
        },
    ]
};

/* LANDING PAGE FAQ SECTION COMPONENT DATA */

export const faqData = {
    title: "Frequently Asked Questions",
    faqs: [
        {
            question: "What services does YUKTII offer?",
            answer: "YUKTII provides a range of services including business acquisitions, technology solutions, and venture support. We specialize in partnering with founders to ensure smooth transitions and continued growth of their businesses."
        },
        {
            question: "How can I partner with YUKTII?",
            answer: "You can partner with YUKTII by reaching out through our contact form on the website. We review all inquiries and will get in touch to discuss potential collaborations and how we can support your business goals."
        },
        {
            question: "What industries do you focus on?",
            answer: "YUKTII focuses on a variety of industries including technology, healthcare, and consumer goods. We are open to exploring opportunities across different sectors to drive innovation and growth."
        },
        {
            question: "Can YUKTII help with technology integration?",
            answer: "Yes, YUKTII offers technology integration services to help businesses streamline their operations and leverage cutting-edge technology. Our team works closely with clients to implement solutions that fit their specific needs."
        },
        {
            question: "How does YUKTII ensure the success of its partners?",
            answer: "We ensure the success of our partners by providing comprehensive support, including strategic guidance, operational assistance, and access to our network of industry experts. Our goal is to drive sustainable growth and create value for all stakeholders."
        },
    ]
};


/* LANDING PAGE WHYCHOOSEUS SECTION COMPONENT DATA */

export const whyChooseUsData = {
    title: "Why Choose Us",
    subtitle: "Discover why over 120,000 users choose Yuktii Ventures.",
    supportItems: [
        {
            title: "Fast and Friendly Support",
            description: "You can always count on our support. Quick troubleshooting or more complicated questions? We're here to help. If you're a beginner, don't worry — our friendly support team will guide you every step of the way.",
            image: "/path/to/image1.jpg"
        },
        {
            title: "Innovative Solutions",
            description: "We provide cutting-edge technology solutions tailored to your needs. Our team of experts is constantly innovating to keep you ahead of the curve.",
            image: "/path/to/image2.jpg"
        },
        {
            title: "Reliable Service",
            description: "With our robust infrastructure and dedicated team, we ensure high uptime and consistent performance for all your critical operations.",
            image: "/path/to/image3.jpg"
        },
        {
            title: "Customizable Options",
            description: "We understand that one size doesn't fit all. That's why we offer flexible and customizable solutions to match your unique business requirements.",
            image: "/path/to/image4.jpg"
        },
        {
            title: "Secure Platform",
            description: "Your data security is our top priority. We employ state-of-the-art security measures to protect your information and maintain your trust.",
            image: "/path/to/image5.jpg"
        },
        {
            title: "Continuous Improvement",
            description: "We're committed to constant growth and improvement. Your feedback drives our development, ensuring our services evolve with your needs.",
            image: "/path/to/image6.jpg"
        },
    ]
};



