import React,{ useEffect } from 'react';
import { FaYoutube, FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import projectPhasesImg from '../../../assets/annampage-images/project-phases.png'
import projectTimelineImg from '../../../assets/annampage-images/project-timeline.png'
import phase1Img from '../../../assets/annampage-images/phase1.png'
import phase2Img from '../../../assets/annampage-images/phase2.png'
import phase3Img from '../../../assets/annampage-images/phase3.png'
import './bhojanamPage.css'

const BhojanamPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="bhojanam-page">
            <h2 className="project-title">BHOJANAM</h2>
            <p className="project-description">
                The Sustainable Breakfast Cereals and Beverages Initiative is a groundbreaking project under the ANNAM (AI-Driven Sustainable Food Systems) platform. This initiative aims to revolutionize the breakfast food industry by promoting sustainability, reducing waste, and engaging communities through innovative AI-driven solutions and digital storytelling.
            </p>
            <img className='project-phases' src={projectPhasesImg} alt="project-phases" />
            <p className="project-description">
                The initiative unfolds over three strategic phases: Engage, Educate, and Transform. Each phase is designed to build upon the previous, creating a comprehensive approach to driving sustainable change in breakfast habits and the broader food system.
            </p>

            <img className='project-timeline' src={projectTimelineImg} alt="project-timeline" />

            <section className="phase-container">
                <div className="phase">
                    <div className='phase-description'>
                        <h2>Phase 1: Engage</h2>
                        <ul>
                            <li>On-field user research with target communities.</li>
                            <li>Conduct focus group discussions and observational studies.</li>
                            <li>Utilize community data mapping for insights.</li>
                            <li>Host a Digital Storytelling Workshop with community members and artists.</li>
                        </ul>
                    </div>
                    <div className='phase-img'>
                        <img src={phase1Img} alt="Phase 1 Image" />
                    </div>
                </div>

                <div className="phase">
                    <div className='phase-img'>
                        <img src={phase2Img} alt="Phase 2 Image" />
                    </div>
                    <div className='phase-description'>
                        <h2>Phase 2: Educate</h2>
                        <ul>
                            <li>Launch the Digital Food Mosaic Storyboard Competition.</li>
                            <li>Participants create visual stories representing sustainable pledges.</li>
                            <li>Gamify learning with points and badges for engagement.</li>
                            <li>Collaborate with local food producers for real-world incentives.</li>
                        </ul>
                    </div>
                </div>

                <div className="phase">
                    <div className='phase-description'>
                        <h2>Phase 3: Transform</h2>
                        <ul>
                            <li>Host an online launch and community celebration for the food mosaic.</li>
                            <li>Advocate for sustainable food systems with municipalities and consumer groups.</li>
                            <li>Provide an open-access digital repository for project findings and resources.</li>
                        </ul>
                    </div>
                    <div className='phase-img'>
                        <img src={phase3Img} alt="Phase 3 Image" />
                    </div>
                </div>
            </section>


            <footer className="b-footer">
                <div className="b-footer-content">
                    <p>BHOJANAM © 2024. All rights reserved.</p>
                    <div className="b-social-icons">
                        <a href="#" aria-label="YouTube"><FaYoutube size={24} /></a>
                        <a href="#" aria-label="Facebook"><FaFacebook size={24} /></a>
                        <a href="https://x.com/Yuktii_Ventures" aria-label="Twitter"><FaXTwitter size={24} /></a>
                        <a href="#" aria-label="Instagram"><FaInstagram size={24} /></a>
                        <a href="https://www.linkedin.com/company/yuktii-ventures/" aria-label="LinkedIn"><FaLinkedin size={24} /></a>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default BhojanamPage;