import React, { useState, useEffect } from 'react';
// import { ChevronLeft, ChevronRight, User } from 'lucide-react';
import { Plus, Minus, ArrowUpRight } from 'lucide-react';
import { FaYoutube, FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import './ParinaamLandingPage.css'
// import desktopImage from '../../assets/parinaampage-images/Desktop.png'
// import dashboardImage from '../../assets/parinaampage-images/Dashboard.png'
// import phoneImage from '../../assets/parinaampage-images/Phone.png'
// import videoImg from '../../assets/parinaampage-images/videoImg.png'
// import googleplay from '../../assets/parinaampage-images/googleplay.png'
// import appstore from '../../assets/parinaampage-images/appstore.png'
// import { FaApple, FaGooglePlay } from 'react-icons/fa';
import { parinaamLandingPageData } from '../../DATA';

const ParinaamLandingPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const { heroSection, featuresSection, videoSection, featuresDetails, reviews, faqs } = parinaamLandingPageData;

    const [currentReview, setCurrentReview] = useState(0);
    const [openFAQ, setOpenFAQ] = useState(null);
    const [showLaunchButton, setShowLaunchButton] = useState(true);
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
    const videoId = "b0-3nSDG52w";

    // const nextReview = () => {
    //     setCurrentReview((prev) => (prev + 1) % reviews.length);
    // };

    // const prevReview = () => {
    //     setCurrentReview((prev) => (prev - 1 + reviews.length) % reviews.length);
    // };

    const toggleFAQ = (index) => {
        setOpenFAQ(openFAQ === index ? null : index);
    };


    useEffect(() => {
        const handleScroll = () => {
            const footer = document.querySelector('.p-footer');
            const footerTop = footer.getBoundingClientRect().top;

            setShowLaunchButton(footerTop > window.innerHeight); // Hide button when footer is in view
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);


    return (
        <div className='p-landing-page'>
            <nav className="p-navbar">
                <div className="p-navbar-container">
                    <div className="p-navbar-logo">PARINAAM</div>
                </div>
            </nav>

            <div className="p-hero-section">
                <div className="p-hero-content">
                    <h1>{heroSection.title}</h1>
                    <p>{heroSection.description}</p>
                    <div className="p-hero-video-container">
                        <iframe
                            width="320"
                            height="180"
                            src={`https://www.youtube.com/embed/${videoId}`}
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </div>
                    {/* <a href={heroSection.ctaLink}>
                        <button className="p-get-started-btn">{heroSection.ctaText}</button>
                    </a> */}
                </div>
                <div className="p-hero-images">
                    {heroSection.images.map((img, index) => (
                        <img key={index} src={img.src} alt={img.alt} className={img.className} />
                    ))}
                </div>
            </div>

            <div className="p-featurecards-section">
                <h2>{featuresSection.title}</h2>
                <div className="p-featurecards-grid">
                    {featuresSection.features.map((feature, index) => (
                        <div key={index} className="p-featurecards-box">{feature}</div>
                    ))}
                </div>
            </div>

            <div className="p-video-section">
                <div className="p-video-content">
                    <h2>{videoSection.title}</h2>
                    <p>{videoSection.description}</p>
                </div>
                <div className="p-video-buttons">
                    <a href="https://www.parinaam.yuktii.com/">
                        <button className="p-primary-action">
                            {videoSection.ctaText}
                        </button>
                    </a>
                </div>
                {/* <div className="p-video-player">
                    <iframe
                        width="560"
                        height="315"
                        src={`https://www.youtube.com/embed/${videoId}`}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div> */}
            </div>

            <div className="p-features-details-section">
                <h2>{featuresDetails.title}</h2>
                <div className="p-features-details-grid">
                    {featuresDetails.features.map((feature, index) => (
                        <div key={index} className="p-feature-detail">
                            <h3>{feature.title}</h3>
                            <p>{feature.description}</p>
                        </div>
                    ))}
                </div>
            </div>

            {/* <div className="p-review-section">
                <div className="p-review-container">
                    <button className="p-review-nav-button" onClick={prevReview}>
                        <ChevronLeft size={24} />
                    </button>
                    <div className="p-review-content">
                        <div className="p-review-icon">
                            <User size={48} />
                        </div>
                        <p className="p-review-text">{reviews[currentReview].text}</p>
                        <h3 className="p-review-name">{reviews[currentReview].name}</h3>
                        <p className="p-review-position">{reviews[currentReview].position}</p>
                    </div>
                    <button className="p-review-nav-button" onClick={nextReview}>
                        <ChevronRight size={24} />
                    </button>
                </div>
            </div> */}

            <div className="p-faq-section">
                <h2>FREQUENTLY ASKED QUESTIONS</h2>
                <div className="p-faq-container">
                    {faqs.map((faq, index) => (
                        <div key={index} className="p-faq-item">
                            <div className="p-faq-question" onClick={() => toggleFAQ(index)}>
                                {faq.question}
                                {openFAQ === index ? <Minus size={24} /> : <Plus size={24} />}
                            </div>
                            {openFAQ === index && (
                                <div className="p-faq-answer">{faq.answer}</div>
                            )}
                        </div>
                    ))}
                </div>
            </div>

            {/* <div className="p-app-download-section">
                <div className="p-app-screenshot">
                    <img src={phoneImage} alt="PARINAAM App Screenshot" />
                </div>
                <div className="p-app-download-content">
                    <h2>Take Your Career Growth Mobile</h2>
                    <p>Download the PARINAAM app to access career insights anytime, anywhere.</p>
                    <div className="p-button-container">
                        <a href="#" className="p-app-store-button">
                            <FaApple className="store-icon" size={24} />
                            <span>Download on the App Store</span>
                        </a>
                        <a href="#" className="p-google-play-button">
                            <FaGooglePlay className="store-icon" size={24} />
                            <span>Get it on Google Play</span>
                        </a>
                    </div>
                </div>
            </div> */}


            {showLaunchButton && (
                <a href="https://parinaam.yuktii.com">
                    <button className="p-launch-button">
                        Launch PARINAAM
                        <ArrowUpRight size={20} />
                    </button>
                </a>
            )}

            <footer className="p-footer">
                <div className="p-footer-content">
                    <p>PARINAAM @ 2024. All rights reserved.</p>
                    <div className="p-social-icons">
                        <a href="#" aria-label="YouTube"><FaYoutube size={24} /></a>
                        <a href="#" aria-label="Facebook"><FaFacebook size={24} /></a>
                        <a href="https://x.com/Yuktii_Ventures" aria-label="Twitter"><FaXTwitter size={24} /></a>
                        <a href="#" aria-label="Instagram"><FaInstagram size={24} /></a>
                        <a href="https://www.linkedin.com/company/yuktii-ventures/" aria-label="LinkedIn"><FaLinkedin size={24} /></a>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default ParinaamLandingPage;